const logoFunctions = {
    init(event) {
        const windowWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        const logo = document.querySelector('.logo')
        const topRow = document.querySelector('._top')

        if (windowWidth > 768) {
            if (event.type === 'resize') {
                logoFunctions.logoPositionByBackgroud(logo);
            }
            logoFunctions.showDarkLogoCleanUp(logo)
        } else {
            logoFunctions.showDarkLogo(logo, topRow.offsetHeight)
            logoFunctions.logoPositionCleanUp(logo);
        }
    },
    showDarkLogo(logo, topRowHeight) {
        const scrollYWhenLogoShouldChanges = topRowHeight - (logo.offsetHeight / 2)
        
        if (window.scrollY > scrollYWhenLogoShouldChanges) {
            logo.classList.add('_show-dark')
        } else {
            logo.classList.remove('_show-dark')
        }
    },
    showDarkLogoCleanUp(logo) {
        logo.classList.remove('_show-dark')
    },
    logoPositionByBackgroud(logo) {
        const background = document.querySelector('.page-background._main')
        const backgroundWidth = background.offsetWidth

        const logoOffsetLeft = backgroundWidth - (logo.offsetWidth / 2)

        logo.setAttribute('style', `left: ${logoOffsetLeft}px`)
    },
    logoPositionCleanUp(logo) {
        logo.setAttribute('style', '')
    }
};

logoFunctions.init({type: 'resize'})
document.addEventListener("scroll", logoFunctions.init);
window.addEventListener("resize", logoFunctions.init);