(() => {
    const toTop = document.querySelector('.to-top')
    const button = toTop.querySelector('.to-top__button')
    if (toTop === null || button === null) {
        return;
    }

    document.addEventListener('scroll', () => {
        if (window.scrollY > 300) {
            toTop.classList.add('_show')
        } else {
            toTop.classList.remove('_show')
        }
    });

    button.addEventListener('click', function (event) {
        event.preventDefault();

        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    });
})()